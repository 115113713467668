import { useEntryFormPage } from '@/hooks/use-entry-form-page/use-entry-form-page'
import { useMemo, useReducer } from 'react'
import { useFormContext } from 'react-hook-form'
import * as Yup from 'yup'
import { Crm, areaRequestServiceId, Purpose } from '@/constants/CrmClasses'
import { FormItemFrame } from '@/components/FormItemFrame'
import { OptionalLabel } from '@/components/OptionalLabel'
import { SupplementText } from '@/components/SupplementText'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { Checkbox } from '@lifedot/atoms/Checkbox'
import { EventTracker } from '@lifedot/tracking'

const styles = {
  label: css({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '24px 1fr',
    gap: 8,
    cursor: 'pointer'
  }),
  supplement: css({
    marginTop: 12
  })
}

const areaRequestSchema = {
  requests: Yup.array()
    .of(
      Yup.object({
        type: Yup.string().required().default(Crm.areaRequest),
        service_id: Yup.string().required().default(areaRequestServiceId),
        purpose: Yup.string()
      })
    )
    .required()
}
const yupObject = Yup.object(areaRequestSchema)
type AreaRequestFormValues = Yup.InferType<typeof yupObject>

export const AreaRequest: React.FC = () => {
  const { cemeteries, bookmarkCemeteries } = useEntryFormPage()
  const index = useMemo(
    () => cemeteries.length + bookmarkCemeteries.length,
    [cemeteries, bookmarkCemeteries]
  )
  const { setValue } = useFormContext<AreaRequestFormValues>()
  const [checked, setChecked] = useReducer((state: boolean) => {
    const nextState = !state
    setValue(`requests.${index}`, {
      type: Crm.areaRequest,
      service_id: areaRequestServiceId,
      purpose: nextState ? Purpose.brochure : undefined
    })

    return nextState
  }, false)

  return (
    <FormItemFrame
      title={
        <>
          <p css={typography.textL}>
            <b>おすすめ霊園の資料</b>
          </p>
          <OptionalLabel />
        </>
      }
    >
      <label css={[typography.textM, styles.label]}>
        <EventTracker
          label="areaPartnerRequest"
          action="click"
          elementLabel="area_partner_request"
          clone
        >
          <Checkbox onChange={setChecked} checked={checked} />
        </EventTracker>
        お問い合わせ霊園付近にある、おすすめ霊園の資料も希望する
      </label>
      <div css={styles.supplement}>
        <SupplementText text="ご検討中のお墓タイプが同じ、お問い合わせ霊園付近（半径10km圏内）の霊園が対象です" />
        <SupplementText text="霊園資料はライフドット提携事業者よりお送りいたします" />
      </div>
    </FormItemFrame>
  )
}
