import Image from 'next/image'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { TelephoneNumber } from '@lifedot/components/TelephoneNumber'
import { EventTracker } from '@lifedot/tracking'

const styles = {
  root: css({
    display: 'flex',
    background: palette.background,
    [mq('sp')]: {
      flexDirection: 'column',
      padding: 12
    }
  }),
  copy: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1
  }),
  img: css({
    marginTop: 16,
    marginRight: 8,
    fontSize: 0,
    [mq('sp')]: {
      width: 58,
      marginTop: 0
    }
  }),
  telephone: css({
    width: 372,
    padding: '20px 0',
    background: palette.white,
    [mq('sp')]: {
      width: '100%',
      padding: '12px 0'
    }
  })
}

export const TourTelephone: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.copy}>
        <div css={styles.img}>
          <Image
            src="https://s3.lifedot.jp/uploads/ending/path/77545/image_staff.png"
            alt="コールセンタースタッフの画像"
            width={87}
            height={84}
          />
        </div>
        <div>
          <p css={typography.headingS}>見学予約はお電話でも承ります</p>
          <p css={typography.textM}>
            <b>当日</b>の見学もお電話から受け付けます
          </p>
        </div>
      </div>
      <div css={styles.telephone}>
        <EventTracker label="tel_link" action="click" elementLabel="tel_link">
          <TelephoneNumber large showNote direct />
        </EventTracker>
      </div>
    </div>
  )
}
