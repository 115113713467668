import { useEffect } from 'react'
import * as Yup from 'yup'
import { Select } from '@/components/Input/Select'
import { useTelContactableTimeSurveyValues } from './dependencies'
import { FormItemFrame } from '@/components/FormItemFrame'
import { typography } from '@lifedot/styles/typography'
import { RequiredLabel } from '@/components/RequiredLabel'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@/components/Input/ErrorMessage'
import { EventTracker } from '@lifedot/tracking'

export const telContactableTimeSchema = {
  survey_entries: Yup.array().of(
    Yup.object({
      survey_id: Yup.string().required(),
      survey_answers: Yup.array().of(
        Yup.object({
          survey_question_id: Yup.string().required(),
          survey_choice_id: Yup.string().required()
        })
      )
    })
  )
}

const yupObject = Yup.object(telContactableTimeSchema)

type TelContactableTimeFormValues = Yup.InferType<typeof yupObject>

export const TelContactableTime: React.FC = () => {
  const { surveyId, questionId, values } = useTelContactableTimeSurveyValues()
  const { watch, setValue } = useFormContext<TelContactableTimeFormValues>()
  useEffect(() => {
    setValue('survey_entries.0.survey_id', surveyId)
    setValue('survey_entries.0.survey_answers.0.survey_question_id', questionId)
  }, [surveyId, questionId, setValue])

  if (values.length < 1) return null

  const contactableTime = watch('survey_entries')
  const allInputted =
    telContactableTimeSchema.survey_entries.isValidSync(contactableTime)

  return (
    <>
      <FormItemFrame
        title={
          <>
            <p css={typography.textL}>
              <b>連絡希望時間</b>
            </p>
            <RequiredLabel allInputted={allInputted} />
          </>
        }
      >
        <EventTracker
          action="click"
          label="telContactableTime"
          elementLabel="tel_contactable_time"
        >
          <Select<TelContactableTimeFormValues>
            name="survey_entries.0.survey_answers.0.survey_choice_id"
            values={values}
            defaultValue={values[0]?.value}
          />
        </EventTracker>
        <ErrorMessage<TelContactableTimeFormValues> name="survey_entries" />
      </FormItemFrame>
    </>
  )
}
