import { graphql } from 'relay-runtime'

export const entryFormIndexQuery = graphql`
  query entryFormCemeteriesQuery($ids: [Int!]!, $bookmarkIds: [Int!]!) {
    cemeteries: listCemeteries(filter: { ids: $ids }) {
      items {
        cemeteryId: id
        name
        prefecture {
          name
        }
        city {
          name
        }
        requestable
        main_image {
          path
          alt
        }
      }
    }
    bookmarkCemeteries: listCemeteries(filter: { ids: $bookmarkIds }) {
      items {
        cemeteryId: id
        name
        prefecture {
          name
        }
        city {
          name
        }
        requestable
        main_image {
          path
          alt
        }
      }
    }
  }
`
