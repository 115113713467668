import { entryFormCemeteriesQuery$data } from '@/queries/__generated__/entryFormCemeteriesQuery.graphql'
import { useEffect, useState } from 'react'
import { FC } from 'react'
import * as Yup from 'yup'
import { TourTimeInput, tourTimeInputSchema } from '@/components/TourTimeInput'
import { useFormContext } from 'react-hook-form'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { Cemetery } from '@/components/Cemetery'
import { Checkbox } from '@lifedot/atoms/Checkbox'
import { Crm, Purpose } from '@/constants/CrmClasses'
import { EventTracker } from '@lifedot/tracking'

const styles = {
  root: css({
    display: 'grid',
    gridTemplateColumns: '3fr 7fr',
    gap: 16,
    [mq('sp')]: {
      gridTemplateColumns: '1fr',
      gap: 8
    }
  }),
  label: css({
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gap: 4,
    cursor: 'pointer'
  }),
  list: css({
    'li + li': {
      marginTop: 8
    }
  })
}

export const reservableCemeterySchema = {
  type: Yup.string().required().default(Crm.cemeteryRequest),
  service_id: Yup.string().required(),
  purpose: Yup.string(),
  ...tourTimeInputSchema
}

const yupObject = Yup.object({
  requests: Yup.array().of(Yup.object(reservableCemeterySchema)).required()
})
export type reservableCemeteryFormValues = Yup.InferType<typeof yupObject>

interface ReservableCemeteryProps {
  cemetery: entryFormCemeteriesQuery$data['cemeteries']['items'][number]
  index: number
}

const puporse = ({ brochure, tour }: { brochure: boolean; tour: boolean }) => {
  if (brochure && tour) return Purpose.both
  if (brochure) return Purpose.brochure
  if (tour) return Purpose.tour
  return undefined
}

export const ReservableCemetery: FC<ReservableCemeteryProps> = ({
  cemetery: {
    name: cemeteryName,
    prefecture: { name: prefectureName },
    city: { name: cityName },
    requestable,
    main_image
  },
  index
}) => {
  const [brochure, setBrochure] = useState(true)
  const [tour, setTour] = useState(false)

  const { setValue } = useFormContext<reservableCemeteryFormValues>()

  useEffect(() => {
    if (!tour) setValue(`requests.${index}.reservations`, [])
  }, [tour, index, setValue])

  useEffect(() => {
    setValue(`requests.${index}.purpose`, puporse({ brochure, tour }))
  }, [brochure, tour, setValue, index])

  return (
    <div css={styles.root}>
      <Cemetery
        cemeteryName={cemeteryName}
        prefectureName={prefectureName}
        cityName={cityName}
        main_image={main_image}
      />
      <ul css={styles.list}>
        <li>
          <label css={styles.label}>
            <Checkbox
              checked={brochure}
              onChange={(e) => setBrochure(e.target.checked)}
            />
            パンフレット請求する
          </label>
        </li>
        <li>
          <label css={styles.label}>
            <EventTracker
              label="tourCheckbox"
              action="click"
              elementLabel="tour_checkbox"
              clone
            >
              <Checkbox
                checked={tour}
                onChange={(e) => setTour(e.target.checked)}
                data-cy="tourCheckbox"
              />
            </EventTracker>
            見学予約する
          </label>
          {tour && <TourTimeInput index={index} requestable={requestable} />}
        </li>
      </ul>
    </div>
  )
}
