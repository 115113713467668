import { FC } from 'react'
import * as Yup from 'yup'
import {
  reservableCemeteryFormValues,
  reservableCemeterySchema
} from './ReservableCemetery'
import { ErrorMessage } from '@/components/Input/ErrorMessage'
import { Crm } from '@/constants/CrmClasses'
import { ReservableCemeteryList } from './ReservableCemeteryList'
import { NotReservableCemeteryList } from './NotReservableCemeteryList'
import { ReservableBookmarkCemeteryList } from './ReservableBookmarkCemeteryList'

export const reservableCemeteriesSchema = {
  requests: Yup.array()
    .of(Yup.object(reservableCemeterySchema))
    .required()
    .compact(
      ({ purpose }: reservableCemeteryFormValues['requests'][number]) =>
        purpose === undefined
    )
    .min(1, '霊園をどれか1つ選択してください')
    .test(
      '',
      '霊園をどれか1つ選択してください',
      (values) => values?.some((v) => v.type === Crm.cemeteryRequest) ?? false
    )
}
const yupObject = Yup.object(reservableCemeteriesSchema)
type ReservableCemeteriesFormValues = Yup.InferType<typeof yupObject>

type ReservableCemeteriesProps = {
  children?: React.ReactNode
}

export const ReservableCemeteries: FC<ReservableCemeteriesProps> = ({
  children
}) => {
  return (
    <section>
      <ReservableCemeteryList />
      {children}
      <NotReservableCemeteryList />
      <ReservableBookmarkCemeteryList />
      <ErrorMessage<ReservableCemeteriesFormValues> name="requests" />
    </section>
  )
}
