import { useEntryFormPage } from '@/hooks/use-entry-form-page/use-entry-form-page'
import { useMemo } from 'react'

export const useTelContactableTimeSurveyValues = (): {
  surveyId: string
  questionId: string
  values: {
    label: string
    value: string
  }[]
} => {
  const { contactableTimeSurveys } = useEntryFormPage()
  return {
    values: useMemo(
      () =>
        contactableTimeSurveys[0]?.survey_questions[0]?.survey_choices.map(
          ({ id, title }) => ({ label: title, value: id.toString() })
        ) ?? [],
      [contactableTimeSurveys]
    ),
    surveyId: contactableTimeSurveys[0]?.id.toString() ?? '',
    questionId:
      contactableTimeSurveys[0]?.survey_questions[0]?.id.toString() ?? ''
  }
}
