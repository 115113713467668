import { FC } from 'react'
import { useEntryFormPage } from '@/hooks/use-entry-form-page/use-entry-form-page'
import { Heading } from '@/components/Heading'
import { partitionCemeteriesByRequestability } from '@/utils/partition-cemeteries-by-requestability'
import { CemeteryListItem } from './CemeteryListItem'

export const ReservableCemeteryList: FC = () => {
  const { cemeteries } = useEntryFormPage()
  const { requestableCemeteries } =
    partitionCemeteriesByRequestability(cemeteries)

  if (requestableCemeteries.length === 0) return null

  return (
    <div>
      <Heading
        text={`お問い合わせする霊園・墓地（${requestableCemeteries.length}件）`}
      />
      <CemeteryListItem cemeteries={requestableCemeteries} />
    </div>
  )
}
