import { FC } from 'react'
import { EntryFormPageContextProvider } from '@/hooks/use-entry-form-page/EntryFormPageContextProvider'
import { InferPageProps, ValidationErrorProps } from '@/runtimes'
import { RequestsNewProps, requestHandler } from '@/runtimes/ohaka/requests/new'
import { useQuery } from 'relay-hooks/lib/useQuery'
import { entryFormCemeteriesQuery } from '@/queries/__generated__/entryFormCemeteriesQuery.graphql'
import { entryFormIndexQuery } from '@/queries/entryForm'
import { NotFound } from '@lifedot/components/NotFound'
import { OhakaRequest } from '@/contents/new/OhakaRequest'
import { OhakaTourRequest } from '@/contents/new/OhakaTourRequest'
import { NoCemetery } from '@/contents/new/NoCemetery'
import { GetServerSideProps } from 'next'
import { ParsedUrlQuery } from 'next-runtime/types/querystring'

export const getServerSideProps = requestHandler

const RequestFormPage: FC<
  InferPageProps<
    GetServerSideProps<ValidationErrorProps | RequestsNewProps, ParsedUrlQuery>
  >
> = (props) => {
  const { ids, bookmarkIds, tour } = props
  const { data, error } = useQuery<entryFormCemeteriesQuery>(
    entryFormIndexQuery,
    {
      ids: ids ?? [],
      bookmarkIds
    }
  )

  if (error) return <NotFound />
  if (!data || !data.cemeteries || data.cemeteries.items.length < 1)
    return <NoCemetery />

  return (
    <EntryFormPageContextProvider
      cemeteries={data.cemeteries.items}
      bookmarkCemeteries={data.bookmarkCemeteries.items}
      {...props}
    >
      {tour ? <OhakaTourRequest /> : <OhakaRequest />}
    </EntryFormPageContextProvider>
  )
}

export default RequestFormPage
