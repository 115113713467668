import { ErrorMessage } from '@/components/Input/ErrorMessage'
import { FC } from 'react'
import * as Yup from 'yup'
import { useFormContext } from 'react-hook-form'
import dayjs from 'dayjs'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { RequiredLabel } from '@/components/RequiredLabel'
import { SelectWithLabel } from '@/components/SelectWithLabel'
import { wantTimeTypes } from '@/constants/WantTimeTypes'
import { Crm } from '@/constants/CrmClasses'
import { EventTracker } from '@lifedot/tracking'
import { TourDatePicker } from '@/components/TourDatePicker'

const styles = {
  required: css({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  inputs: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 12
  }),
  label: css({
    fontWeight: 'bold'
  })
}

export const tourTimeInputSchema = {
  reservations: Yup.array().of(
    Yup.object({
      type: Yup.string().required().default(Crm.tourReservation),
      reserving_date: Yup.string()
        .required('希望の日付を入力してください')
        .transform((v: string) => dayjs(v).format('YYYY-MM-DD')),
      reserving_time: Yup.string()
    })
  )
}

const yupObject = Yup.object({
  requests: Yup.array().of(Yup.object(tourTimeInputSchema))
})
type TourTimeInputFormValues = Yup.InferType<typeof yupObject>

interface TourTimeInputProps {
  index: number
  requestable: boolean
}

export const TourTimeInput: FC<TourTimeInputProps> = ({
  index,
  requestable
}) => {
  const { watch } = useFormContext<TourTimeInputFormValues>()
  const value = watch(`requests.${index}.reservations.0`)
  const allInputted = tourTimeInputSchema.reservations.isValidSync([value])

  return (
    <div>
      <p css={[typography.textM, styles.required]}>
        見学希望日を入力してください
        <RequiredLabel allInputted={allInputted} />
      </p>
      <div css={styles.inputs}>
        <div>
          <label
            css={[typography.textS, styles.label]}
            htmlFor={`requests.${index}.reservations.0.reserving_date`}
          >
            日付
          </label>
          <EventTracker
            label="reservingDate"
            action="click"
            elementLabel="reserving_date"
          >
            <TourDatePicker<TourTimeInputFormValues>
              name={`requests.${index}.reservations.0.reserving_date`}
              requestable={requestable}
            />
          </EventTracker>
          <ErrorMessage<TourTimeInputFormValues>
            name={`requests.${index}.reservations.0.reserving_date`}
          />
        </div>
        <EventTracker
          label="reservingTime"
          action="click"
          elementLabel="reserving_time"
        >
          <SelectWithLabel<TourTimeInputFormValues>
            name={`requests.${index}.reservations.0.reserving_time`}
            values={wantTimeTypes}
            title="時間帯"
            id={`requests.${index}.reservations.0.reserving_time`}
          />
        </EventTracker>
      </div>
    </div>
  )
}
