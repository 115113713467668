/**
 * @generated SignedSource<<5dd60662095a56f041129a67d96965c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime'
export type entryFormCemeteriesQuery$variables = {
  ids: ReadonlyArray<number>
  bookmarkIds: ReadonlyArray<number>
}
export type entryFormCemeteriesQuery$data = {
  readonly cemeteries: {
    readonly items: ReadonlyArray<{
      readonly cemeteryId: number
      readonly name: string
      readonly prefecture: {
        readonly name: string
      }
      readonly city: {
        readonly name: string
      }
      readonly requestable: boolean
      readonly main_image: {
        readonly path: string | null
        readonly alt: string | null
      } | null
    }>
  }
  readonly bookmarkCemeteries: {
    readonly items: ReadonlyArray<{
      readonly cemeteryId: number
      readonly name: string
      readonly prefecture: {
        readonly name: string
      }
      readonly city: {
        readonly name: string
      }
      readonly requestable: boolean
      readonly main_image: {
        readonly path: string | null
        readonly alt: string | null
      } | null
    }>
  }
}
export type entryFormCemeteriesQuery = {
  variables: entryFormCemeteriesQuery$variables
  response: entryFormCemeteriesQuery$data
}

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'bookmarkIds'
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'ids'
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    v3 = [v2 /*: any*/],
    v4 = [
      {
        alias: null,
        args: null,
        concreteType: 'Cemetery',
        kind: 'LinkedField',
        name: 'items',
        plural: true,
        selections: [
          {
            alias: 'cemeteryId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          },
          v2 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPrefecture',
            kind: 'LinkedField',
            name: 'prefecture',
            plural: false,
            selections: v3 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryCity',
            kind: 'LinkedField',
            name: 'city',
            plural: false,
            selections: v3 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'requestable',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryImage',
            kind: 'LinkedField',
            name: 'main_image',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'path',
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'alt',
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    v5 = [
      {
        alias: 'cemeteries',
        args: [
          {
            fields: [
              {
                kind: 'Variable',
                name: 'ids',
                variableName: 'ids'
              }
            ],
            kind: 'ObjectValue',
            name: 'filter'
          }
        ],
        concreteType: 'CemeteryResult',
        kind: 'LinkedField',
        name: 'listCemeteries',
        plural: false,
        selections: v4 /*: any*/,
        storageKey: null
      },
      {
        alias: 'bookmarkCemeteries',
        args: [
          {
            fields: [
              {
                kind: 'Variable',
                name: 'ids',
                variableName: 'bookmarkIds'
              }
            ],
            kind: 'ObjectValue',
            name: 'filter'
          }
        ],
        concreteType: 'CemeteryResult',
        kind: 'LinkedField',
        name: 'listCemeteries',
        plural: false,
        selections: v4 /*: any*/,
        storageKey: null
      }
    ]
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'entryFormCemeteriesQuery',
      selections: v5 /*: any*/,
      type: 'Query',
      abstractKey: null
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'entryFormCemeteriesQuery',
      selections: v5 /*: any*/
    },
    params: {
      cacheID: '6bfce796c16bbf3ca55f48b6ec1f8a58',
      id: null,
      metadata: {},
      name: 'entryFormCemeteriesQuery',
      operationKind: 'query',
      text: 'query entryFormCemeteriesQuery(\n  $ids: [Int!]!\n  $bookmarkIds: [Int!]!\n) {\n  cemeteries: listCemeteries(filter: {ids: $ids}) {\n    items {\n      cemeteryId: id\n      name\n      prefecture {\n        name\n      }\n      city {\n        name\n      }\n      requestable\n      main_image {\n        path\n        alt\n      }\n    }\n  }\n  bookmarkCemeteries: listCemeteries(filter: {ids: $bookmarkIds}) {\n    items {\n      cemeteryId: id\n      name\n      prefecture {\n        name\n      }\n      city {\n        name\n      }\n      requestable\n      main_image {\n        path\n        alt\n      }\n    }\n  }\n}\n'
    }
  }
})()

;(node as any).hash = '8926aac08c635f891444cdc273f29359'

export default node
