import type { Survey } from '@/api/Surveys'
import { entryFormCemeteriesQuery$data } from '@/queries/__generated__/entryFormCemeteriesQuery.graphql'
import { createContext, PropsWithChildren } from 'react'
import { FC } from 'react'

export interface EntryFormPageContextValue {
  tour: boolean
  cemeteries: entryFormCemeteriesQuery$data['cemeteries']['items']
  bookmarkCemeteries: entryFormCemeteriesQuery$data['bookmarkCemeteries']['items']
  contactableTimeSurveys: Survey[]
  reservingDate: string
  section_types: string[]
}

export const EntryFormPageContext = createContext<EntryFormPageContextValue>({
  tour: false,
  cemeteries: [],
  bookmarkCemeteries: [],
  contactableTimeSurveys: [],
  reservingDate: '',
  section_types: []
})

export const EntryFormPageContextProvider: FC<
  PropsWithChildren<EntryFormPageContextValue>
> = ({
  children,
  tour,
  cemeteries,
  bookmarkCemeteries,
  contactableTimeSurveys,
  reservingDate,
  section_types
}) => (
  <EntryFormPageContext.Provider
    value={{
      tour,
      cemeteries,
      bookmarkCemeteries,
      contactableTimeSurveys,
      reservingDate,
      section_types
    }}
  >
    {children}
  </EntryFormPageContext.Provider>
)
