import { RequestForm } from '@/components/RequestForm'
import {
  TelContactableTime,
  telContactableTimeSchema
} from '@/components/TelContactableTime'
import * as Yup from 'yup'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import {
  dataConvertFunction,
  useOhakaTourRequestDefaultValues
} from './dependencies'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import {
  ContactCemeteries,
  contactCemeteriesSchema
} from '@/components/ContactCemeteries'
import { TourTelephone } from '@/components/TourTelephone'
import { Heading } from '@/components/Heading'
import { UserName, userNameSchema } from '@/components/UserName'
import { UserTel, userTelSchema } from '@/components/UserTel'
import { UserEmail, userEmailSchema } from '@/components/UserEmail'
import { SectionTypes, sectionTypesSchema } from '@/components/SectionTypes'
import { MailMagazine, mailMagazineSchema } from '@/components/MailMagazine'
import { SubmitButtonWithAgreement } from '@/components/SubmitButtonWithAgreement'
import { JPXAndSSL } from '@/components/JPXAndSSL'
import { New } from '@/constants/FormActions'
import { TrackingCategory } from '@/constants/TrackingCategories'
import { useCvTracking } from '@/hooks/use-cv-tracking'
import InfoIcon from '@material-ui/icons/Info'
import { TwoStepsStyled } from '@/components/Steps'
import { ErrorMessageBox } from '@/components/ErrorMessageBox'
import { ga4EventPush } from '@lifedot/tracking'
import { NoCemetery } from '@/contents/new/NoCemetery'
import { CampaignBanner } from '@lifedot/components/CampaignBanner'
import { CampaignNote } from '@lifedot/components/CampaignNote'
import { ReCAPTCHA } from '@/components/ReCAPTCHA'

const styles = {
  tel: css({
    [mq('sp')]: {
      margin: '0 -12px 0'
    }
  }),
  content: css({
    [mq('sp')]: {
      padding: '0 12px'
    }
  }),
  form: css({
    marginTop: 8,
    [mq('sp')]: {
      margin: '8px -12px 0'
    },
    'dl + dl': {
      borderTop: 'none'
    }
  }),
  caption: css({
    color: palette.main03,
    marginTop: 4
  }),
  icon: css({
    verticalAlign: 'text-top'
  }),
  note: css({
    maxWidth: 700,
    margin: 'auto'
  })
}

const schema = Yup.object({
  action: Yup.string().required().default(New.OhakaTourRequest),
  ...contactCemeteriesSchema,
  ...userNameSchema,
  ...userTelSchema,
  ...telContactableTimeSchema,
  ...userEmailSchema,
  ...sectionTypesSchema,
  ...mailMagazineSchema
})

export type OhakaTourRequestFormValues = Yup.InferType<typeof schema>

type ResponseData = {
  code: string
  ids: Array<number>
}

export const OhakaTourRequest: React.FC = () => {
  const defaultValues = useOhakaTourRequestDefaultValues()
  const cvTracking = useCvTracking({
    category: TrackingCategory.CV_COMPLETE,
    label: '見学予約'
  })

  if (!defaultValues.requests?.length) return <NoCemetery />

  const onSuccess = ({ code, ids }: ResponseData) => {
    cvTracking()
    ga4EventPush({
      event: 'purchase',
      type: '見学予約',
      transactionId: code,
      itemId: ids
    })
  }

  return (
    <RequestForm<OhakaTourRequestFormValues, ResponseData>
      schema={schema}
      defaultValues={defaultValues}
      onSuccess={onSuccess}
      dataConvertFunction={dataConvertFunction}
    >
      <div css={styles.content}>
        <AddMarginWrapper spacing={8}>
          <AddMarginWrapper spacing={6}>
            <div css={styles.tel}>
              <TourTelephone />
            </div>
            <TwoStepsStyled currentStep={1} />
            <ContactCemeteries />
            <CampaignBanner showDetail />
            <section>
              <Heading text="見学される方について" />
              <p css={[typography.textS, styles.caption]}>
                <InfoIcon style={{ fontSize: 14 }} css={styles.icon} />
                お客様の情報が一般に公開されることは一切ありませんのでご安心ください
              </p>
              <div css={styles.form}>
                <UserName />
                <UserTel />
                <TelContactableTime />
                <UserEmail />
                <SectionTypes showNote />
                <MailMagazine />
              </div>
              <ReCAPTCHA />
            </section>
          </AddMarginWrapper>
          <ErrorMessageBox />
          <SubmitButtonWithAgreement />
          <JPXAndSSL />
          <div css={styles.note}>
            <CampaignNote />
          </div>
        </AddMarginWrapper>
      </div>
    </RequestForm>
  )
}
