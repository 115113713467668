import { entryFormCemeteriesQuery$data } from '@/queries/__generated__/entryFormCemeteriesQuery.graphql'

export const partitionCemeteriesByRequestability = (
  cemeteriesArrays: entryFormCemeteriesQuery$data['cemeteries']['items']
) => {
  if (!cemeteriesArrays || cemeteriesArrays.length === 0)
    return { requestableCemeteries: [], notRequestableCemeteries: [] }

  return cemeteriesArrays.reduce<{
    requestableCemeteries: entryFormCemeteriesQuery$data['cemeteries']['items']
    notRequestableCemeteries: entryFormCemeteriesQuery$data['cemeteries']['items']
  }>(
    (partitionedCemeteries, cemetery) => {
      const key = cemetery.requestable
        ? 'requestableCemeteries'
        : 'notRequestableCemeteries'
      partitionedCemeteries[key] = [...partitionedCemeteries[key], cemetery]
      return partitionedCemeteries
    },
    { requestableCemeteries: [], notRequestableCemeteries: [] }
  )
}
