import { useRouter } from 'next/router'
import { useCallback, FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { Banner } from '@/components/Banner'
import { Button } from '@lifedot/atoms/Button'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  message: css({
    padding: '100px 0',
    textAlign: 'center',
    [mq('sp')]: {
      padding: '80px 12px'
    }
  }),
  messageHead: css({
    color: palette.main03,
    marginBottom: 4
  }),
  button: css({
    maxWidth: 480,
    margin: 'auto',
    [mq('sp')]: {
      padding: '0 12px'
    }
  }),
  sp: css({
    display: 'none',
    [mq('sp')]: {
      display: 'block'
    }
  })
}

export const NoCemetery: FC = () => {
  const router = useRouter()
  const handleClick = useCallback(() => router.push('/'), [router])

  return (
    <>
      <Banner />
      <section css={styles.message}>
        <h1 css={[typography.headingL, styles.messageHead]}>
          お問い合わせできる霊園が選択されていません
        </h1>
        <p css={typography.textM}>
          霊園を選択していただくと、
          <br css={styles.sp} />
          申し込みが出来るようになります
        </p>
      </section>
      <div css={styles.button}>
        <Button
          mainText={<span css={typography.textXL}>トップに戻る</span>}
          onClick={handleClick}
        />
      </div>
    </>
  )
}
