import { useEntryFormPage } from '@/hooks/use-entry-form-page/use-entry-form-page'
import { useMemo } from 'react'
import { FormSchemaType } from './OhakaRequest'
import { DefaultValues } from 'react-hook-form'
import { Purpose } from '@/constants/CrmClasses'
import { New } from '@/constants/FormActions'
import { partitionCemeteriesByRequestability } from '@/utils/partition-cemeteries-by-requestability'

export const useOhakaRequest = (): DefaultValues<FormSchemaType> => {
  const { cemeteries, bookmarkCemeteries, section_types } = useEntryFormPage()
  return useMemo(() => {
    const { requestableCemeteries } = partitionCemeteriesByRequestability([
      ...cemeteries,
      ...bookmarkCemeteries
    ])

    return {
      requests: requestableCemeteries.map((cemetery) => ({
        service_id: String(cemetery.cemeteryId)
      })),
      section_types
    }
  }, [cemeteries, bookmarkCemeteries, section_types])
}

export const dataConvertFunction = ({
  action,
  ...customer
}: FormSchemaType): {
  action: FormSchemaType['action']
  customer: Omit<FormSchemaType, 'action'>
  requestIds: number[]
} => {
  const containTour = customer.requests.some((request) =>
    [Purpose.both, Purpose.tour].includes(request.purpose ?? '')
  )
  const requestIds = customer.requests
    .map((request) => Number(request.service_id))
    .filter(isFinite)

  return {
    action: containTour ? New.OhakaRequestWithTour : New.OhakaRequest,
    customer,
    requestIds
  }
}
