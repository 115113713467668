import type { OhakaTourRequestFormValues } from './OhakaTourRequest'
import { DefaultValues } from 'react-hook-form'
import { useEntryFormPage } from '@/hooks/use-entry-form-page/use-entry-form-page'
import { partitionCemeteriesByRequestability } from '@/utils/partition-cemeteries-by-requestability'

export const useOhakaTourRequestDefaultValues =
  (): DefaultValues<OhakaTourRequestFormValues> => {
    const { cemeteries, reservingDate } = useEntryFormPage()
    const { requestableCemeteries } =
      partitionCemeteriesByRequestability(cemeteries)

    return {
      requests: requestableCemeteries.map((cemetery) => ({
        service_id: String(cemetery.cemeteryId),
        reservations: [
          {
            reserving_date: reservingDate || undefined
          }
        ]
      })),
      survey_entries: []
    }
  }

export const dataConvertFunction = ({
  action,
  ...customer
}: OhakaTourRequestFormValues): {
  action: OhakaTourRequestFormValues['action']
  customer: Omit<OhakaTourRequestFormValues, 'action'>
  requestIds: number[]
} => {
  const requestIds = customer.requests
    .map((request) => Number(request.service_id))
    .filter(isFinite)

  return {
    action,
    customer,
    requestIds
  }
}
