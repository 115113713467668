import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { ReservableCemetery } from './ReservableCemetery'
import { entryFormCemeteriesQuery$data } from '@/queries/__generated__/entryFormCemeteriesQuery.graphql'

const styles = {
  list: css({
    backgroundColor: palette.white,
    border: `1px solid ${palette.gray3}`,
    padding: '0 12px',
    maxHeight: 240,
    overflowY: 'scroll',
    marginTop: 8,
    '> li': {
      paddingTop: 12,
      marginBottom: 12,
      ':not(:first-of-type)': {
        borderTop: `1px solid ${palette.gray2}`
      }
    }
  })
}

export const CemeteryListItem: FC<{
  cemeteries: entryFormCemeteriesQuery$data['cemeteries']['items']
  cemeteryLength?: number
}> = ({ cemeteries, cemeteryLength = 0 }) => {
  return (
    <ul css={styles.list}>
      {cemeteries.map((cemetery, index) => (
        <li key={cemetery.cemeteryId}>
          <ReservableCemetery
            cemetery={cemetery}
            index={cemeteryLength + index}
          />
        </li>
      ))}
    </ul>
  )
}
