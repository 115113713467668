import { entryFormCemeteriesQuery$data } from '@/queries/__generated__/entryFormCemeteriesQuery.graphql'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { TourTimeInput, tourTimeInputSchema } from '@/components/TourTimeInput'
import * as Yup from 'yup'
import { Cemetery } from '@/components/Cemetery'
import { Crm, Purpose } from '@/constants/CrmClasses'

const styles = {
  root: css({
    display: 'grid',
    gridTemplateColumns: '3fr 7fr',
    gap: 16,
    [mq('sp')]: {
      gridTemplateColumns: '1fr',
      gap: 8
    }
  })
}

export const contactCemeterySchema = {
  type: Yup.string().required().default(Crm.cemeteryRequest),
  service_id: Yup.string().required(),
  purpose: Yup.string().default(Purpose.tour),
  ...tourTimeInputSchema
}

type ContactCemeteryProps = {
  cemetery: entryFormCemeteriesQuery$data['cemeteries']['items'][number]
  index: number
}

export const ContactCemetery: React.FC<ContactCemeteryProps> = ({
  cemetery,
  index
}) => {
  const {
    main_image,
    name: cemeteryName,
    prefecture: { name: prefectureName },
    city: { name: cityName },
    requestable
  } = cemetery

  return (
    <div css={styles.root}>
      <Cemetery
        main_image={main_image}
        cemeteryName={cemeteryName}
        prefectureName={prefectureName}
        cityName={cityName}
      />
      <TourTimeInput index={index} requestable={requestable} />
    </div>
  )
}
