import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { Heading } from '@/components/Heading'
import { useEntryFormPage } from '@/hooks/use-entry-form-page/use-entry-form-page'
import { ContactCemetery, contactCemeterySchema } from './ContactCemetery'
import { partitionCemeteriesByRequestability } from '@/utils/partition-cemeteries-by-requestability'
import * as Yup from 'yup'

const styles = {
  list: css({
    backgroundColor: palette.white,
    border: `1px solid ${palette.gray3}`,
    padding: '0 12px',
    maxHeight: 242,
    overflowY: 'scroll',
    marginTop: 8,
    '> li': {
      paddingTop: 12,
      marginBottom: 12,
      ':not(:first-of-type)': {
        borderTop: `1px solid ${palette.gray2}`
      }
    }
  }),
  caption: css({
    marginTop: 8
  })
}

export const contactCemeteriesSchema = {
  requests: Yup.array(Yup.object(contactCemeterySchema)).required().min(1)
}

export const ContactCemeteries: React.FC = () => {
  const { cemeteries } = useEntryFormPage()
  const { requestableCemeteries } =
    partitionCemeteriesByRequestability(cemeteries)
  return (
    <>
      <Heading
        text={`見学希望日の入力${
          requestableCemeteries.length
            ? `（${requestableCemeteries.length}件）`
            : ''
        }`}
      />
      <p css={[typography.textS, styles.caption]}>
        ライフドットカスタマーサポートがご希望の日時にて見学日程の調整を行います
      </p>
      <ul css={styles.list}>
        {requestableCemeteries.map((cemetery, index) => (
          <li key={index}>
            <ContactCemetery cemetery={cemetery} index={index} />
          </li>
        ))}
      </ul>
    </>
  )
}
