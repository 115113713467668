import { FC } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { useEntryFormPage } from '@/hooks/use-entry-form-page/use-entry-form-page'
import { palette } from '@lifedot/styles/palette'
import { Heading } from '@/components/Heading'
import InfoIcon from '@material-ui/icons/Info'
import { partitionCemeteriesByRequestability } from '@/utils/partition-cemeteries-by-requestability'
import { Cemetery } from '@/components/Cemetery'

const styles = {
  content: css({
    marginTop: 24
  }),
  list: css({
    backgroundColor: palette.white,
    border: `1px solid ${palette.gray3}`,
    padding: '0 12px',
    maxHeight: 240,
    overflowY: 'scroll',
    marginTop: 8,
    '> li': {
      paddingTop: 12,
      marginBottom: 12,
      ':not(:first-of-type)': {
        borderTop: `1px solid ${palette.gray2}`
      }
    }
  }),
  icon: css({
    verticalAlign: 'text-top'
  }),
  caption: css({
    color: palette.main03,
    marginTop: 4
  })
}

export const NotReservableCemeteryList: FC = () => {
  const { cemeteries } = useEntryFormPage()
  const { notRequestableCemeteries } =
    partitionCemeteriesByRequestability(cemeteries)

  if (notRequestableCemeteries.length === 0) return null

  return (
    <div css={styles.content}>
      <Heading
        text={`お問い合わせできない霊園・墓地（${notRequestableCemeteries.length}件）`}
      />
      <p css={[typography.textS, styles.caption]}>
        <InfoIcon style={{ fontSize: 14 }} css={styles.icon} />
        当サイトではお問い合わせを受け付けておりません。個別に霊園へお問い合わせください
      </p>
      <ul css={styles.list}>
        {notRequestableCemeteries.map((cemetery) => {
          const {
            cemeteryId,
            name,
            prefecture: { name: prefectureName },
            city: { name: cityName },
            main_image
          } = cemetery
          return (
            <li key={cemeteryId}>
              <Cemetery
                cemeteryName={name}
                prefectureName={prefectureName}
                cityName={cityName}
                main_image={main_image}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
