
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/ohaka/requests/new",
      function () {
        return require("private-next-pages/ohaka/requests/new.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/ohaka/requests/new"])
      });
    }
  