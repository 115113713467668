import { FC } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { useEntryFormPage } from '@/hooks/use-entry-form-page/use-entry-form-page'
import { partitionCemeteriesByRequestability } from '@/utils/partition-cemeteries-by-requestability'
import { CemeteryListItem } from './CemeteryListItem'

const styles = {
  bookmark: css({
    marginTop: 16
  })
}

export const ReservableBookmarkCemeteryList: FC = () => {
  const { cemeteries, bookmarkCemeteries } = useEntryFormPage()
  const { requestableCemeteries } =
    partitionCemeteriesByRequestability(cemeteries)
  const { requestableCemeteries: bookmark } =
    partitionCemeteriesByRequestability(bookmarkCemeteries)

  if (bookmark.length === 0) return null

  return (
    <div css={styles.bookmark}>
      <h3 css={typography.textM}>
        検討リストに追加した霊園（{bookmark.length}
        件）も一緒に問い合わせますか？
      </h3>
      <p css={typography.textS}>不要な場合はチェックを外してください</p>
      <CemeteryListItem
        cemeteries={bookmark}
        cemeteryLength={requestableCemeteries.length}
      />
    </div>
  )
}
