import { FC } from 'react'
import { UserName, userNameSchema } from '@/components/UserName'
import { UserTel, userTelSchema } from '@/components/UserTel'
import { UserAddress, userAddressSchema } from '@/components/UserAddress'
import { SectionTypes, sectionTypesSchema } from '@/components/SectionTypes'
import {
  ReservableCemeteries,
  reservableCemeteriesSchema
} from '@/components/ReservableCemeteries'
import * as Yup from 'yup'
import { dataConvertFunction, useOhakaRequest } from './dependencies'
import { RequestForm } from '@/components/RequestForm'
import { typography } from '@lifedot/styles/typography'
import { mq } from '@lifedot/styles/mediaQuery'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { css } from '@emotion/react'
import { Banner } from '@/components/Banner'
import { New } from '@/constants/FormActions'
import { JPXAndSSL } from '@/components/JPXAndSSL'
import { Heading } from '@/components/Heading'
import { palette } from '@lifedot/styles/palette'
import InfoIcon from '@material-ui/icons/Info'
import { SubmitButtonWithAgreement } from '@/components/SubmitButtonWithAgreement'
import { AreaRequest } from '@/components/AreaRequest'
import { TrackingCategory } from '@/constants/TrackingCategories'
import { useCvTracking } from '@/hooks/use-cv-tracking'
import { ThreeStepsStyled } from '@/components/Steps'
import { ErrorMessageBox } from '@/components/ErrorMessageBox'
import { ga4EventPush } from '@lifedot/tracking'
import { NoCemetery } from '@/contents/new/NoCemetery'
import { CampaignBanner } from '@lifedot/components/CampaignBanner'
import { CampaignNote } from '@lifedot/components/CampaignNote'
import { ReCAPTCHA, reCAPTCHASchema } from '@/components/ReCAPTCHA'

const styles = {
  content: css({
    [mq('sp')]: {
      padding: '0 12px'
    }
  }),
  banner: css({
    [mq('sp')]: {
      margin: '0 -12px 0'
    }
  }),
  form: css({
    marginTop: 8,
    [mq('sp')]: {
      margin: '8px -12px 0'
    },
    'dl + dl': {
      borderTop: 'none'
    }
  }),
  caption: css({
    color: palette.main03,
    marginTop: 4
  }),
  icon: css({
    verticalAlign: 'text-top'
  }),
  note: css({
    maxWidth: 700,
    margin: 'auto'
  })
}

const schema = Yup.object({
  action: Yup.string().required().default(New.OhakaRequest),
  ...reservableCemeteriesSchema,
  ...userNameSchema,
  ...userTelSchema,
  ...userAddressSchema,
  ...sectionTypesSchema,
  ...reCAPTCHASchema
})

export type FormSchemaType = Yup.InferType<typeof schema>

type ResponseData = {
  code: string
  ids: Array<number>
}

export const OhakaRequest: FC = () => {
  const defaultValues = useOhakaRequest()
  const cvTracking = useCvTracking({
    category: TrackingCategory.CV_COMPLETE,
    label: '通常'
  })

  if (!defaultValues.requests?.length) return <NoCemetery />
  const isSetDefaultSectionTypes = Boolean(defaultValues.section_types?.length)

  const onSuccess = ({ code, ids }: ResponseData) => {
    cvTracking()
    ga4EventPush({
      event: 'purchase',
      type: '通常',
      transactionId: code,
      itemId: ids
    })
  }

  return (
    <RequestForm<FormSchemaType, ResponseData>
      schema={schema}
      defaultValues={defaultValues}
      onSuccess={onSuccess}
      dataConvertFunction={dataConvertFunction}
    >
      <div css={styles.content}>
        <AddMarginWrapper spacing={8}>
          <AddMarginWrapper spacing={6}>
            <div css={styles.banner}>
              <Banner />
            </div>
            <ThreeStepsStyled currentStep={1} />
            <ReservableCemeteries>
              {isSetDefaultSectionTypes && <SectionTypes showNote />}
            </ReservableCemeteries>
            <CampaignBanner showDetail />
            <section>
              <Heading text="お客様情報の入力" />
              <p css={[typography.textS, styles.caption]}>
                <InfoIcon style={{ fontSize: 14 }} css={styles.icon} />
                お客様の情報が一般に公開されることは一切ありませんのでご安心ください
              </p>
              <div css={styles.form}>
                <UserName />
                <UserTel />
                <UserAddress />
                {!isSetDefaultSectionTypes && <SectionTypes showNote />}
                <AreaRequest />
              </div>
              <ReCAPTCHA />
            </section>
          </AddMarginWrapper>
          <ErrorMessageBox />
          <SubmitButtonWithAgreement />
          <JPXAndSSL />
          <div css={styles.note}>
            <CampaignNote />
          </div>
        </AddMarginWrapper>
      </div>
    </RequestForm>
  )
}
